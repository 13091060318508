import { useStaticQuery, graphql } from "gatsby";
import Link from "../components/link";
import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Hero from "../components/hero";
import SvgFanHp from "../components/svg/fan-svg-hp";
import SvgBannerHp from "../components/svg/banner-svg-hp";
import SvgCrown from "../components/svg/crown-svg";
import PhotoSide from "../components/photo-side";
import CountdownTimer from "../components/countdown-timer";
import TopRightSection from "../components/top-right-section";
import TopLeftSection from "../components/top-left-section";
import BottomBanner from "../components/bottom-banner";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import TopRightSectionBigger from "../components/top-right-section-bigger";
import TopRightFlateBottom from "../components/top-right-flat-bottom";
import useOnScreen from "../components/use-on-screen";
import gsap from "gsap";
import ReactPlayer from "react-player";

const IndexPage = () => {
	const responsive = {
		0: {
			items: 1,
		},
		768: {
			items: 2,
		},
		992: {
			items: 3,
		},
	};
	const data = useStaticQuery(graphql`
		query {
			# heroImg: wpMediaItem(title: { eq: "carnival-png" }) {

			heroImg: wpMediaItem(title: { eq: "2024-carnival" }) {
				#   heroImg: wpMediaItem(title: { eq: "2023-carnival-banner" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroMessage: wpMediaItem(title: { eq: "hero-message" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			routeImg: wpMediaItem(title: { eq: "CARNIVAL ROUTE MAP UPDATED" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			queenImg: wpMediaItem(title: { eq: "index-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			LFImg: wpMediaItem(title: { eq: "Lightning-Fibre-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ESCImg: wpMediaItem(title: { eq: "Enterprise-Shopping-Centre-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g1Img: wpMediaItem(title: { eq: "gal-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g2Img: wpMediaItem(title: { eq: "gal-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g3Img: wpMediaItem(title: { eq: "gal-3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g4Img: wpMediaItem(title: { eq: "gal-4" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g5Img: wpMediaItem(title: { eq: "gal-5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g6Img: wpMediaItem(title: { eq: "gal-6" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g7Img: wpMediaItem(title: { eq: "Gallery-Image-7-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g8Img: wpMediaItem(title: { eq: "ebncarnival-2023-21" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g9Img: wpMediaItem(title: { eq: "ebncarnival-2023-118" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g10Img: wpMediaItem(title: { eq: "ebncarnival-2023-38" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g11Img: wpMediaItem(title: { eq: "ebncarnival-2023-37" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g12Img: wpMediaItem(title: { eq: "ebncarnival-2023-122" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			g13Img: wpMediaItem(title: { eq: "ebncarnival-2023-32" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			hpsImg: wpMediaItem(title: { eq: "index-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tShirtImg: wpMediaItem(title: { eq: "T-shirts" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const heroMessage =
		data.heroMessage?.localFile.childImageSharp.gatsbyImageData;
	const queenImg = data.queenImg?.localFile.childImageSharp.gatsbyImageData;
	const routeImg = data.routeImg?.localFile.childImageSharp.gatsbyImageData;
	const LFImg = data.LFImg?.localFile.childImageSharp.gatsbyImageData;
	const ESCImg = data.ESCImg?.localFile.childImageSharp.gatsbyImageData;
	const g1Img = data.g1Img?.localFile.childImageSharp.gatsbyImageData;
	const g2Img = data.g2Img?.localFile.childImageSharp.gatsbyImageData;
	const g3Img = data.g3Img?.localFile.childImageSharp.gatsbyImageData;
	const g4Img = data.g4Img?.localFile.childImageSharp.gatsbyImageData;
	const g5Img = data.g5Img?.localFile.childImageSharp.gatsbyImageData;
	const g6Img = data.g6Img?.localFile.childImageSharp.gatsbyImageData;
	const g7Img = data.g7Img?.localFile.childImageSharp.gatsbyImageData;
	const g8Img = data.g8Img?.localFile.childImageSharp.gatsbyImageData;
	const g9Img = data.g9Img?.localFile.childImageSharp.gatsbyImageData;
	const g10Img = data.g10Img?.localFile.childImageSharp.gatsbyImageData;
	const g11Img = data.g11Img?.localFile.childImageSharp.gatsbyImageData;
	const g12Img = data.g12Img?.localFile.childImageSharp.gatsbyImageData;
	const g13Img = data.g13Img?.localFile.childImageSharp.gatsbyImageData;
	const hpsImg = data.hpsImg?.localFile.childImageSharp.gatsbyImageData;
	const tShirtImg = data.tShirtImg?.localFile.childImageSharp.gatsbyImageData;
	const ref1 = useRef();
	const ref2 = useRef();
	const onScreen1 = useOnScreen(ref1, "0px");
	const onScreen2 = useOnScreen(ref2, "100px");

	// useEffect(() => {
	// 	if (onScreen1 === true) {
	// 		gsap.to(".home-heading-1", {
	// 			opacity: 1,
	// 			duration: 1,
	// 			delay: 0.2,
	// 			transform: "scale(1)",
	// 		});
	// 	}
	// }, [onScreen1]);
	useEffect(() => {
		if (onScreen2 === true) {
			gsap.to(".purple-section", {
				opacity: 1,
				duration: 1,
				delay: 0.4,
				transform: "scale(1)",
			});
			gsap.to(".yellow-section", {
				opacity: 1,
				duration: 1,
				delay: 0.7,
				transform: "scale(1)",
			});
		}
	}, [onScreen2]); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	const imgs = [
		<div className="item" data-value="1">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g1Img}
				alt={data.g1Img?.altText}
			/>
		</div>,
		<div className="item" data-value="2">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g2Img}
				alt={data.g2Img?.altText}
			/>
		</div>,
		<div className="item" data-value="3">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g3Img}
				alt={data.g3Img?.altText}
			/>
		</div>,
		<div className="item" data-value="4">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g4Img}
				alt={data.g4Img?.altText}
			/>
		</div>,
		<div className="item" data-value="5">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g5Img}
				alt={data.g5Img?.altText}
			/>
		</div>,
		<div className="item" data-value="6">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g6Img}
				alt={data.g6Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g7Img}
				alt={data.g7Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g8Img}
				alt={data.g8Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g9Img}
				alt={data.g9Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g10Img}
				alt={data.g10Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g11Img}
				alt={data.g11Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g12Img}
				alt={data.g12Img?.altText}
			/>
		</div>,
		<div className="item" data-value="7">
			<GatsbyImage
				style={{ maxWidth: "100%", height: "25rem" }}
				image={g13Img}
				alt={data.g13Img?.altText}
			/>
		</div>,
	];

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Eastbourne Carnival | Join us on the 24th May 2025"
				description="Following on from a fabulous, colourful celebration of Sussex in 24, Carnival is back in Eastbourne on Saturday 24th May 2025"
				// description="Eastbourne has historically had a town carnival for decades in the 20th century but did have an absence of 20 years until Stephen Lloyd, then MP for Eastbourne brought it back in 2011."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title:
						"Eastbourne Carnival | See you at carnival on Saturday 24th May 2025!",
					description:
						"Following on from a fabulous, colourful celebration of Sussex in 24, Carnival is back in Eastbourne on Saturday 24th May 2025",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>
			<section className="position-relative">
				<Hero
					backgroundImage={heroImg}
					title="WELCOME TO EASTBOURNE CARNIVAL!"
					messageImage={heroMessage}
				/>
				<TopRightSection
					content={
						<Row className="pb-5 pb-md-0">
							<Col ref={ref1} className="text-center">
								<div className="pt-5 pt-md-10">
									<Button
										style={{ opacity: 0, transform: "scale(0.95)" }}
										variant="lightBlue"
										className="fs-5 py-3 w-100 w-md-auto me-md-4 button-fade-1 text-white jost-normal"
										as={Link}
										to="/entrant-form"
									>
										APPLY TO ENTER <span className="text-secondary">C</span>
										<span className="text-primary">A</span>
										<span className="text-white">R</span>
										<span className="text-mainRed">N</span>
										<span className="text-secondary">I</span>
										<span className="text-white">V</span>
										<span className="text-primary">A</span>
										<span className="text-mainRed">L</span>
									</Button>
									<Button
										style={{ opacity: 0, transform: "scale(0.95)" }}
										className="fs-5 py-3 w-100 w-md-auto me-md-4  button-fade-2 mt-4 mt-md-0 text-white jost-normal"
										to="/volunteer-form"
										as={Link}
									>
										VOLUNTEER ON THE DAY
									</Button>
									<Button
										style={{ opacity: 0, transform: "scale(0.95)" }}
										variant="mainRed"
										className="fs-5 py-3 w-100 w-md-auto mt-4 mt-lg-0 button-fade-1 px-4 text-white jost-normal"
										rel="noreferrer"
										target="_blank"
										href="https://docs.google.com/forms/d/e/1FAIpQLSewah9bWMV9c74TXJcqqezVw5a5isViwUay0hObxvTPH9ecjA/viewform"
									>
										VOTE NOW
									</Button>
								</div>
								<h2
									style={{
										lineHeight: "150%",

										transform: "scale(0.95)",
									}}
									className="display-2 text-primary home-heading-1 d-none d-md-inline-block carnival-bold pt-2 pt-md-3  pb-0 mb-0 "
								>
									See you at carnival on Saturday 24th May 2025!
								</h2>
								<h2
									style={{
										lineHeight: "150%",
									}}
									className="display-2 text-primary d-md-none carnival-bold pt-2 pt-md-3 d-inline-block pb-0 mb-0 "
								>
									See you at carnival on Saturday 24th May 2025!
								</h2>
							</Col>
						</Row>
					}
					mainBg="bg-secondary"
					secondBg="bg-primary"
					position="top--20"
				/>
			</section>
			<section>
				<Container>
					<Row className=" mb-5 gy-4 mt-5 mt-lg-0 g-lg-5 mb-lg-7 ">
						<Col lg={6}>
							<ReactPlayer
								playing
								loop
								controls
								playsinline
								muted
								title="Eastbourne Carnival One"
								className="  w-100 h-auto"
								url="https://eastbourne-carnival.rjmdigital.net/wp-content/uploads/2024/02/Eastbourne-Carnival-final.mp4"
							/>
						</Col>
						<Col lg={6}>
							<ReactPlayer
								playing
								loop
								controls
								playsinline
								muted
								title="Eastbourne Carnival Two"
								className="  w-100 h-auto"
								url="https://eastbourne-carnival.rjmdigital.net/wp-content/uploads/2024/02/The-Beacon.mp4"
							/>
						</Col>
					</Row>
				</Container>
				<Container>
					<PhotoSide
						image={queenImg}
						imageAlt={data.queenImg?.altText}
						order="last"
						orderSmall="last"
						heading={
							<div>
								<h2 className=" d-none d-lg-block display-6 pb-0 mb-0 carnival-bold d-inline-block">
									<span className="text-secondary">WELCOME</span>
									<span className="text-primary"> TO</span>
									<span className="text-lightBlue"> EASTBOURNE</span>
									<span className="text-mainRed"> CARNIVAL!</span>
								</h2>
								<h2 className=" d-lg-none text-center display-4 pb-0 mb-0 carnival-bold d-inline-block">
									<span className="text-secondary">WELCOME</span>
									<span className="text-primary"> TO</span>
									<span className="text-lightBlue"> EASTBOURNE</span>
									<span className="text-mainRed"> CARNIVAL!</span>
								</h2>
								<SvgBannerHp className="w-100 w-md-90 " />
							</div>
						}
						text={
							<span>
								<p>
									Following on from a fabulous, colourful celebration of Sussex
									in 2023, Carnival is back in Eastbourne on Saturday 25th May
									2024, and our theme is ‘Into The Wild’.
								</p>
								<p>
									This year, for 2024, we want the community to get excited and
									creative about the beauty of the natural environment.
								</p>
								<p>
									This theme gives our entrants the chance to create marvellous,
									colourful costumes and displays around animals, birds, sea
									creatures, and flora and fauna.
								</p>
								<p>
									You could be creating something which is relevant to the UK,
									Sussex or, something from lands further away e.g. tropical
									rainforests, savannahs – the choice is yours!{" "}
								</p>
								<p>Our judges will be awarding prizes for: </p>
								<ls>
									<li>The Best Costume</li>
									<li>The Best Float</li>
									<li>The Best Performance</li>
								</ls>
								<br />
								<p>
									Get involved! See how you can become an entrant, a volunteer,
									a Sponsor or Partner on our webpages.
								</p>
							</span>
						}
					/>
				</Container>
			</section>
			<section className="position-relative">
				<TopLeftSection
					content={<CountdownTimer />}
					position="bottom--20"
					mainBg="bg-primary"
					secondBg="bg-secondary"
				/>

				<Container className="px-0 mx-0" fluid>
					<Row className="gx-0">
						<Col
							xs={{ span: 12, order: "last" }}
							xl={{ span: 6, order: "first" }}
						>
							<GatsbyImage
								className="w-100 home-image-side"
								image={hpsImg}
								alt={data.hpsImg?.altText}
							/>
						</Col>
						<Col
							className="bg-lightBlue text-white px-md-10 px-4 d-flex align-items-center pt-5 pt-md-10 "
							xl={6}
						>
							<div className="d-block pb-6">
								<p>
									<strong className="jost-bold">Join us</strong> on Saturday
									24th May 2025 for an afternoon of Carnival events. (times are
									approx.)
								</p>
								<ls>
									<li>
										Our Opening Procession will parade from the Enterprise
										Shopping Centre from midday.
									</li>
									<br />
									<li>
										The Main Carnival parade will start at 2pm from Fishermen’s
										Green and will end at the Wish Tower Slopes. At approx.
										4:30pm
									</li>
									<br />
									<li>
										A street party will then take place at Victoria Place (top
										end of Terminus Road) from approx. 4:30pm.
									</li>
								</ls>
							</div>
						</Col>
					</Row>
				</Container>

				<TopRightFlateBottom
					content={
						<Row className="pb-5 pb-md-0">
							<Col className=" pt-5 pt-md-10 text-center">
								{/* <p className="display-3 text-white d-none d-md-block carnival-bold">
									<span className="">MERCH </span>
									<span className="text-lightBlue">AVAILABLE</span>
									<span className="text-mainRed"> SOON!</span>
								</p>
								<p className="display-3 text-white d-md-none carnival-bold">
									<span className="">MERCH </span>
									<span className="text-lightBlue">AVAILABLE</span>
									<span className="text-mainRed"> SOON!</span>
								</p>
								<br />
								<p className="text-primary pb-4">
									Eastbourne Carnival T Shirts will be available early 2024,{" "}
									<br className="d-none d-lg-block" />
									to help get you in the spirit of this years’ theme.
								</p> */}
								<h2 className=" display-2 d-none d-md-block text-primary carnival-bold">
									MERCH OUT NOW!
								</h2>
								<h2 className=" display-1 d-md-none text-primary carnival-bold">
									MERCH OUT NOW!
								</h2>
								<p className="display-4 text-white d-none d-md-block carnival-bold">
									<span className="">GET </span>
									<span className="text-lightBlue">CARNIVAL</span>
									<span className="text-mainRed"> READY</span>
								</p>
								<p className="display-3 text-white d-md-none carnival-bold">
									<span className="">GET </span>
									<span className="text-lightBlue">CARNIVAL</span>
									<span className="text-mainRed"> READY</span>
								</p>

								<p className="text-primary pb-4">
									Grab yourself a Carnival souvenir T-shirt,{" "}
									<br className="d-none d-lg-block" />
									kids size also available!
								</p>
								<Button
									variant="lightBlue"
									className="fs-5 py-3 mt-3 mb-0  w-100 w-md-auto mx-auto px-5 text-white jost-normal"
									rel="noreferrer"
									target="_blank"
									href="https://ashprint.co.uk/shop/21-eastbourne-carnival-clothing"
								>
									ONLINE MERCH STORE
								</Button>
							</Col>
						</Row>
					}
					mainBg="bg-secondary"
					secondBg="bg-lightPurple"
					position="top--20"
				/>
			</section>

			{/* <section className="position-relative py-md-10 py-6">
				<SvgFanHp
					style={{ zIndex: -1, opacity: 0.1, width: "auto", height: "140%" }}
					className="position-absolute   top--20 start-50 translate-middle-x "
				/>

				<Container>
					<Row>
						<Col className="text-center">
							<p className="text-secondary shortstack fs-2">
								A HUGE THANKS TO OUR
							</p>
							<h2 className="display-2 carnival-bold  text-primary ">
								Platinum Sponsors
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-center pt-4 align-items-center">
						<Col xs={6} lg={4}>
							<a
								rel="noreferrer"
								target="_blank"
								href="https://enterprise-centre.org/"
							>
								<GatsbyImage image={ESCImg} alt={data.ESCImg?.altText} />
							</a>
						</Col>
						<Col xs={6} lg={4}>
							<a
								rel="noreferrer"
								target="_blank"
								href="https://www.lightningfibre.co.uk/"
							>
								{" "}
								<GatsbyImage image={LFImg} alt={data.LFImg?.altText} />
							</a>
						</Col>
					</Row>
					<Row>
						<Col className="text-center pt-5">
							{" "}
							<Button
								variant="secondary"
								className="fs-5 py-3 w-100 w-md-auto mx-auto px-5 text-white jost-normal"
								as={Link}
								to="/our-sponsors"
							>
								Sponsor the Carnival
							</Button>
						</Col>
					</Row>
				</Container>
			</section> */}

			<section>
				<Container className="px-0 position-relative height-smaller mx-0" fluid>
					<Row
						style={{ height: "25rem" }}
						className=" position-relative top-higher px-0 mx-0"
					>
						<AliceCarousel
							className="px-0 h-100"
							infinite={true}
							disableButtonsControls
							disableDotsControls
							responsive={responsive}
							items={imgs}
							autoPlayInterval={1000}
							animationDuration={1000}
							autoPlay
							controlsStrategy="alternate"
						/>
					</Row>
				</Container>

				<GatsbyImage
					style={{ width: "100%" }}
					image={routeImg}
					alt={data.routeImg?.altText}
				/>
				<div className="bg-lightBlue">
					<Container>
						<Row className="pb-5  pb-md-0 position-relative">
							{/* <StaticImage
								src="../images/fireworks-svg.svg"
								style={{
									zIndex: 1,
									overflow: "visible",

									maxWidth: "30%",
								}}
								className="position-absolute  d-none d-md-block fire-works-1  "
								quality={100}
								placeholder="Traced_Svg"
							/> */}
							<div className="position-absolute start--30 bottom-10">
								<div class="firework"></div>
								<div class="firework"></div>
								<div class="firework"></div>
							</div>
							<div className="position-absolute fire-works-right end--30 bottom-30">
								<div class="firework"></div>
								<div class="firework"></div>
								<div class="firework"></div>
							</div>
							{/* <StaticImage
								src="../images/fireworks-2.svg"
								style={{
									zIndex: 1,
									overflow: "visible",

									maxWidth: "40%",
								}}
								className="position-absolute d-none d-md-block fire-works-2    "
								quality={100}
								placeholder="Traced_Svg"
							/> */}
							<Col
								style={{ zIndex: 2 }}
								className="py-md-10 py-5 position-relative text-center "
							>
								<h2 className=" display-2 d-none d-md-block text-white carnival-bold">
									SPONSOR THE CARNIVAL!
								</h2>
								<h2 className=" display-1 d-md-none text-white carnival-bold">
									SPONSOR THE CARNIVAL!
								</h2>

								<Button
									variant="secondary"
									className="fs-5 py-3 mt-5  w-100 w-md-auto mx-auto px-5 text-white jost-normal"
									as={Link}
									to="/background-and-event-information"
								>
									FIND OUT MORE
								</Button>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="px-0 mx-0" fluid>
					{" "}
					<Row ref={ref2} className="h-100">
						<Col style={{ minHeight: "100%" }} className="px-0  mx-0" lg={6}>
							<div
								style={{
									minHeight: "100%",
								}}
								className="px-xl-10 px-5 px-lg-7  bg-secondary text-center position-relative pb-md-10 pb-6 pt-md-8 pt-6 text-primary"
							>
								<div
									className="purple-section"
									style={{ opacity: 0, transform: "scale(0.95)" }}
								>
									<h3 className="text-center pb-4 shortstack">
										About Eastbourne Carnival
									</h3>
									<p className="text-start pb-4">
										Eastbourne Carnival is a community organisation, organised
										by a volunteer committee. Most of the committee have been
										involved with Carnival since it came back in 2011. You can
										make enquiries, suggestions and contact us via email, or via
										our social media channels.
									</p>
								</div>
								<Button
									variant="lightBlue"
									className="fs-5 mx-5 mx-md-0 d-none mt-lg-6 mt-xl-5 d-lg-inline-block py-3 mx-auto px-5 position-absolute start-50 translate-middle-x top-70 text-white jost-normal"
									as={Link}
									to="/about-eastbourne-carnival"
								>
									FIND OUT MORE
								</Button>
								<Button
									variant="lightBlue"
									className="fs-5 py-3 mx-5 mx-md-0 w-100 w-md-auto d-lg-none mx-auto px-5 text-white jost-normal "
									as={Link}
									to="/about-eastbourne-carnival"
								>
									FIND OUT MORE
								</Button>
							</div>
						</Col>
						<Col className="px-0 mx-0" lg={6}>
							<div className="px-xl-10 px-lg-7  px-5 bg-primary text-center position-relative pb-md-10 pb-6 pt-md-8 pt-6 text-secondary">
								<div
									className="yellow-section"
									style={{ opacity: 0, transform: "scale(0.95)" }}
								>
									<h3 className="text-center pb-4 shortstack">
										The theme for this year is ‘Into The Wild. We want the
										community to get excited and creative about the beauty of
										the natural environment.
									</h3>
									<p className="text-start ">
										This theme gives entrants the chance to create marvellous,
										colourful costumes and displays around animals, birds, sea
										creatures, and flora and fauna.
									</p>
									<p className="text-start ">
										You could be creating something which is relevant to the UK,
										Sussex or, something from lands further away e.g. tropical
										rainforests, savannahs – the choice is yours!
									</p>
									<p className="text-start">
										{" "}
										We will be supporting participants with workshops to help
										with costume making – please keep an eye on our Social Media
										and News section for updates.
									</p>
									<p className="text-start pb-lg-10">
										{" "}
										There is also a{" "}
										<a
											className="text-secondary text-hover-white"
											href="https://www.facebook.com/groups/eastbournecarnivalentrants"
										>
											Facebook Group
										</a>{" "}
										for Entrants.
									</p>
								</div>
								<Button
									variant="secondary"
									className="fs-5 py-3 mx-5 mx-md-0 d-none mt-lg-6 mt-xl-5 d-lg-inline-block mx-auto px-5 text-white jost-normal position-absolute start-50 translate-middle-x top-70"
									as={Link}
									to="/further-information"
								>
									FIND OUT MORE
								</Button>
								<Button
									variant="secondary"
									className="fs-5 py-3 mx-5 mt-4 mx-md-0 w-100 w-md-auto d-lg-none mx-auto px-5 text-white jost-normal "
									as={Link}
									to="/further-information"
								>
									FIND OUT MORE
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<BottomBanner
				mainBg="bg-lightBlue"
				position="top--20"
				content={
					<Row>
						<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
							<h2 className="text-white text-center  fs-1 shortstack">
								FOLLOW US ON SOCIAL MEDIA
							</h2>
							<div className="d-flex pt-4 justify-content-center align-items-center">
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.facebook.com/eastbournecarnival"
								>
									<FaFacebookF className="text-white display-2 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://twitter.com/EBCarnival"
								>
									<FaTwitter className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.instagram.com/eastbournecarnival/"
								>
									<FaInstagram className="text-white display-2 ms-5 link-white" />
								</a>
								<a
									rel="noreferrer"
									target="_blank"
									href="https://www.youtube.com/@eastbournecarnival990"
								>
									<FaYoutube className="text-white display-2 ms-5 link-white" />
								</a>
							</div>
						</Col>
					</Row>
				}
			/>
		</Layout>
	);
};

export default IndexPage;
