import React from "react";
import Container from "react-bootstrap/Container";

const TopRightSectionBigger = ({ content, mainBg, secondBg, position }) => {
	return (
		<div className="position-relative angled-div-larger">
			<div className="d-none d-md-block">
				<div
					style={{ zIndex: 1 }}
					className={`div-up-left-medium ${secondBg} position-absolute w-100 h-100 ${position} `}
				></div>
				<div
					style={{ zIndex: 2 }}
					className={`div-up-right-larger  ${mainBg} position-absolute w-100 h-100 ${position} `}
				>
					<Container className="pt-6">{content}</Container>
				</div>
			</div>
			<div
				style={{ zIndex: 2 }}
				className={`div-up-right-larger d-md-none ${mainBg}  `}
			>
				<Container>{content}</Container>
			</div>
		</div>
	);
};

export default TopRightSectionBigger;
