import * as React from "react";

const SvgBannerHp = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={819.673}
		height={53.016}
		viewBox="0 0 819.673 53.016"
		{...props}
	>
		<g id="Group_34" data-name="Group 34" transform="translate(-121.999 -1951)">
			<g id="Group_33" data-name="Group 33" transform="translate(0 22.349)">
				<path
					id="Path_9"
					data-name="Path 9"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 122.5, 1941.556)"
					fill="#49a840"
				/>
				<path
					id="Path_43"
					data-name="Path 43"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 166.5, 1941.556)"
					fill="#607eb2"
				/>
				<path
					id="Path_44"
					data-name="Path 44"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 207.5, 1941.556)"
					fill="#191a15"
				/>
				<path
					id="Path_45"
					data-name="Path 45"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 250.5, 1941.556)"
					fill="#b46590"
				/>
				<path
					id="Path_46"
					data-name="Path 46"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 291.432, 1941.556)"
					fill="#f5af29"
				/>
				<path
					id="Path_47"
					data-name="Path 47"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 332.432, 1941.556)"
					fill="#e43230"
				/>
				<path
					id="Path_48"
					data-name="Path 48"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 374.037, 1941.556)"
					fill="#f5af29"
				/>
				<path
					id="Path_49"
					data-name="Path 49"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 413.037, 1941.556)"
					fill="#472540"
				/>
				<path
					id="Path_50"
					data-name="Path 50"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 452.605, 1941.556)"
					fill="#49a840"
				/>
				<path
					id="Path_51"
					data-name="Path 51"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 492.872, 1941.556)"
					fill="#607eb2"
				/>
				<path
					id="Path_52"
					data-name="Path 52"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 534.872, 1941.556)"
					fill="#e43230"
				/>
				<path
					id="Path_53"
					data-name="Path 53"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 577.491, 1941.556)"
					fill="#b46590"
				/>
				<path
					id="Path_54"
					data-name="Path 54"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 617, 1941.556)"
					fill="#f5af29"
				/>
				<path
					id="Path_55"
					data-name="Path 55"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 656, 1941.667)"
					fill="#607eb2"
				/>
				<path
					id="Path_56"
					data-name="Path 56"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 698.004, 1941.556)"
					fill="#e43230"
				/>
				<path
					id="Path_57"
					data-name="Path 57"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 737.132, 1941.667)"
					fill="#472540"
				/>
				<path
					id="Path_58"
					data-name="Path 58"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 776.132, 1941.667)"
					fill="#607eb2"
				/>
				<path
					id="Path_59"
					data-name="Path 59"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 816.132, 1942.796)"
					fill="#49a840"
				/>
				<path
					id="Path_60"
					data-name="Path 60"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 855.132, 1942.796)"
					fill="#f5af29"
				/>
				<path
					id="Path_61"
					data-name="Path 61"
					d="M5.628,41.636s1.991-1.67,4.924-4.2c1.466-1.262,3.167-2.74,4.973-4.329s3.726-3.326,5.625-5.051,3.777-3.455,5.529-5.094,3.377-3.188,4.76-4.513c2.764-2.65,4.573-4.428,4.573-4.428s-2.286-.79-5.719-1.973c-1.706-.614-3.7-1.333-5.827-2.1-.534-.189-1.073-.395-1.618-.6s-1.1-.418-1.653-.629L17.824,7.435,14.475,6.1c-1.1-.443-2.2-.865-3.242-1.3L5.527,2.415,0,0S.242,2.577.657,6.467c.209,1.946.462,4.222.753,6.665s.63,5.024.982,7.63.721,5.222,1.089,7.661S4.21,33.14,4.528,35.1s.594,3.593.79,4.738.31,1.8.31,1.8"
					transform="matrix(0.934, -0.358, 0.358, 0.934, 893.132, 1942.796)"
					fill="#e43230"
				/>
			</g>
			<line
				id="Line_2"
				data-name="Line 2"
				x2={811}
				y2={1}
				transform="translate(122 1963.5)"
				fill="none"
				stroke="#b46590"
				strokeWidth={1}
			/>
		</g>
	</svg>
);

export default SvgBannerHp;
