import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CountdownTimer = () => {
	const calculateTimeLeft = () => {
		let date = +new Date(`05/24/2025`).setHours(14);
		let difference = date - +new Date();

		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				Minutes: Math.floor((difference / 1000 / 60) % 60),
				Seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearTimeout(timer);
	});
	const timerComponents = [];

	Object.keys(timeLeft).forEach((interval, index) => {
		if (index === 0) {
			timerComponents.push(
				<Col xs={3}>
					<p className=" fs-3 text-white text-center">{interval} </p>
					<p className="text-secondary jost-bold text-center display-3">
						{timeLeft[interval]}
					</p>
				</Col>
			);
		}
		if (index === 1) {
			timerComponents.push(
				<Col xs={3}>
					<p className="  fs-3 text-white text-center">{interval} </p>
					<p className="text-white jost-bold display-3 text-center">
						{timeLeft[interval]}
					</p>
				</Col>
			);
		}
		if (index === 2) {
			timerComponents.push(
				<Col xs={3}>
					<p className=" fs-3 text-white text-center">{interval} </p>
					<p className="text-lightBlue jost-bold text-center display-3">
						{timeLeft[interval]}
					</p>
				</Col>
			);
		}
		if (index === 3) {
			timerComponents.push(
				<Col xs={3}>
					<p className=" fs-3  text-white text-center">{interval} </p>

					<p className="text-mainRed jost-bold text-center display-3">
						{timeLeft[interval]}
					</p>
				</Col>
			);
		}
	});

	return (
		<Row className="justify-content-center">
			<Col lg={8}>
				<Row className=" py-5 pb-md-0 mt-md-4 mt-lg-0 pt-md-10">
					<h2 className="text-secondary fs-1 text-center shortstack pb-4">
						The countdown is on
					</h2>
					{timerComponents.length ? timerComponents : <span>Time's up!</span>}
				</Row>
			</Col>
		</Row>
	);
};

export default CountdownTimer;
