import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Button from "react-bootstrap/Button";
import Link from "./link";
import gsap from "gsap";
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({ title, description, type, backgroundImage, messageImage }) => {
	const pluginImage = getImage(backgroundImage);

	useEffect(() => {
		gsap.to(".heading-fade", {
			opacity: 1,
			duration: 1,
			delay: 0.5,
			transform: "scale(1)",
		});

		gsap.to(".button-fade-1", {
			opacity: 1,
			duration: 1,
			delay: 1.1,
			transform: "scale(1)",
		});
		gsap.to(".button-fade-2", {
			opacity: 1,
			duration: 1,
			delay: 1.4,
			transform: "scale(1)",
		});
	}, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

	return (
		<BgImage
			style={{ backgroundPosition: "center" }}
			Tag="section"
			className="home-hero pb-xl-10"
			image={pluginImage}
		>
			<div
				style={{
					zIndex: "-1",
				}}
				className="position-absolute w-100 h-100"
			></div>
			<Container className="pt-lg-1 px-lg-5 pb-5 pb-md-0 ">
				<Row className=" pt-0 align-items-lg-end">
					<Col className="pl-0 ps-md-3">
						{/* <div className="text-center">
              <GatsbyImage image={messageImage} alt={"Into The Wild"} />
            </div> */}
						<h1
							style={{ opacity: 0, transform: "scale(0.95)" }}
							className="pt-3 mb-3 carnival-normal heading-fade text-secondary text-center main-hero-heading  "
							id="services-hero-title"
						>
							{title}
						</h1>
						<div className="d-md-none" style={{ height: "350px" }}></div>
						<p className="mb-lg-0 text-white fs-1 shortstack">{description}</p>
					</Col>
				</Row>
				<Row className="pt-4 pb-lg-10">
					<Col className="text-center">
						{/* <Button
              style={{ opacity: 0, transform: "scale(0.95)" }}
              variant="secondary"
              className="fs-5 py-3 w-100 w-md-auto me-4  button-fade-1 text-white jost-normal"
              as={Link}
              to="/entrant-form"
            >
              APPLY TO ENTER <span className="text-lightBlue">C</span>
              <span className="text-primary">A</span>
              <span className="text-white">R</span>
              <span className="text-mainRed">N</span>
              <span className="text-lightPurple">I</span>
              <span className="text-white">V</span>
              <span className="text-primary">A</span>
              <span className="text-mainRed">L</span>
            </Button>
            <Button
              style={{ opacity: 0, transform: "scale(0.95)" }}
              className="fs-5 py-3 w-100 w-md-auto  button-fade-2 mt-4 mt-md-0 text-white jost-normal"
              to="/volunteer-form"
              as={Link}
            >
              VOLUNTEER ON THE DAY
            </Button> */}
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default Hero;
